import React from "react"
import { Link } from "gatsby"

import {
  Box,
  Button,
  FadeWrapper,
  Heading,
  Paragraph,
  SEO,
  Wrapper,
} from "components"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found - Teine Energy" />

    <Box as="section" pt={[128, 224]}>
      <Wrapper position="relative">
        <FadeWrapper>
          <Box p={["layout.3", "layout.5"]} bg="wash">
            <Box maxWidth={1080 / 1.5}>
              <Heading size={800}>Page not found</Heading>
              <Paragraph mt="layout.2">
                You just hit a route that doesn&#39;t exist. This page may have
                moved or been deleted.
              </Paragraph>
              <Button as={Link} to="/" mt="layout.4">
                Go Home
              </Button>
            </Box>
          </Box>
        </FadeWrapper>
      </Wrapper>
    </Box>
  </>
)

export default NotFoundPage
